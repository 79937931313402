import React from 'react'
import Layout from '../layouts/siteLayout'
import { useStaticQuery, graphql } from 'gatsby'
import { StyledSection } from '../styles'
import Landing2 from '../components/landing2'
import GoogleMaps from '../components/googleMaps'

const DirectionsPage = () => {
  const directionsData = useStaticQuery(graphql`
    query directionsQuery {
      wpgraphql {
        pageBy(id: "cGFnZToxNg==") {
          tournament_location {
            tournamentLocationName
            tournamentAddress
          }
        }
      }
    }
  `)

  const {
    wpgraphql: {
      pageBy: {
        tournament_location: { tournamentLocationName, tournamentAddress },
      },
    },
  } = directionsData

  return (
    <Layout>
      <Landing2 header="Directions" subheader={tournamentLocationName} address={tournamentAddress} />
      <GoogleMaps />
      <StyledSection></StyledSection>
    </Layout>
  )
}

export default DirectionsPage
