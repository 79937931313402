import React, { useState, memo } from 'react'
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

const StyledInfoWindow = styled.div`
  color: ${({ theme }) => theme.subprimary};

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.subprimary};

    h3 {
      margin: 0.5rem;
    }

    p {
      margin: 0 0.5rem;
    }

    small {
      margin: 0 0.5rem;
    }
  }
`

const GoogleMaps = (props) => {
  const [showingInfoWindow, setShowingInfoWindow] = useState(false)
  const [activeMarker, setActiveMarker] = useState({})
  const [selectedPlace, setSelectedPlace] = useState({})

  const googleMapsData = useStaticQuery(graphql`
    query googleMapsQuery {
      wpgraphql {
        pageBy(id: "cGFnZToxNg==") {
          tournament_location {
            googleMapsAddressUrl
            tournamentLocationName
            tournamentAddress
          }
        }
      }
    }
  `)

  const {
    wpgraphql: {
      pageBy: {
        tournament_location: { googleMapsAddressUrl, tournamentLocationName, tournamentAddress },
      },
    },
  } = googleMapsData

  const latLong = googleMapsAddressUrl
    .substring(googleMapsAddressUrl.lastIndexOf('@') + 1, googleMapsAddressUrl.lastIndexOf('z'))
    .split(',')

  const formattedLatitude = latLong[0]
  const formattedLongitude = latLong[1]

  const onMarkerClick = (props, marker) => {
    setSelectedPlace(props)
    setActiveMarker(marker)
    setShowingInfoWindow(true)
  }

  const onMapClicked = (props) => {
    if (showingInfoWindow) {
      setShowingInfoWindow(false)
      setActiveMarker(null)
    }
  }

  return (
    <div style={{ height: '50vh', paddingRight: '15px', position: 'relative' }}>
      <Map
        google={props.google}
        zoom={10}
        initialCenter={{ lat: formattedLatitude, lng: formattedLongitude }}
        style={{ height: '100%', paddingRight: '15px', position: 'relative' }}
        onClick={onMapClicked}
      >
        <Marker
          position={{ lat: formattedLatitude, lng: formattedLongitude }}
          onClick={onMarkerClick}
          myLocation
        ></Marker>

        {activeMarker && activeMarker.myLocation ? (
          <InfoWindow marker={activeMarker} visible={showingInfoWindow}>
            <StyledInfoWindow>
              <a href={googleMapsAddressUrl} rel="noopener noreferrer" target="_blank">
                <h3>{tournamentLocationName}</h3>
                <p>{tournamentAddress}</p>
              </a>
            </StyledInfoWindow>
          </InfoWindow>
        ) : (
          <InfoWindow marker={activeMarker} visible={showingInfoWindow}>
            <div>
              <h1>{selectedPlace.name}</h1>
              <a href={selectedPlace.url} rel="noopener noreferrer" target="_blank">
                <p>{selectedPlace.address}</p>
              </a>
              <small>
                {(parseFloat(selectedPlace.distance) * 1.60934).toFixed(2)}
                km away from the event location
              </small>
            </div>
          </InfoWindow>
        )}
      </Map>
    </div>
  )
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBdSNSVuC4oXxo_urQyQDWCZ8We1TJmkoQ',
})(memo(GoogleMaps))
